* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

html {
  overflow-x: hidden;
  overflow-y: hidden;
  background: black;
}

/* landing screen */

.landing-screen {
  background: black;
  /* border: 1px solid purple; */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.album-title {
  position: absolute;
  font-family: "Lato";
  font-size: 8rem;
  color: white;
  top: 5%;
  z-index: 3;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.album-wrapper {
  /* border: 1px solid greenyellow; */
  height: 100vh;
  width: 50%;
  position: relative;
}

.album-bg {
  /* border: 1px solid orange; */
  filter: blur(20px);
  height: 100%;
  width: 100%;
  /* opacity: 45%; */
}

.one {
  filter: none;
  background: black;
  background-size: cover;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.two {
  background: url("img/albums/havenArtwork.png") no-repeat center center;
  background-size: cover;
  z-index: 0;
  filter: blur(20px);
  opacity: 45%;
}

.album-main-wrapper {
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.album-main {
  width: 55%;
  height: auto;
  filter: drop-shadow(black 0 0 30px);
  /* border: 1px solid green; */
}

.listen-button {
  text-decoration: none;
  margin-top: 50px;
  padding: 20px;
  font-size: 20px;
  font-family: "Lato";
  border: 3px solid white;
  color: white;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.site-button-text {
  text-decoration: none;
  z-index: 2;
  margin-top: 50px;
  padding: 20px;
  font-size: 2.2rem;
  font-family: "Lato";
  border: 3px solid white;
  color: white;

  margin: auto;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.separator {
  height: 100%;
  width: 15px;
  background: black;
  z-index: 2;
  filter: blur(3px);
}

/* /landing screen/ */

/* album-share */

.album-share {
  z-index: 0;
}
.album-share-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  mix-blend-mode: color;
  margin-top: 50px;
  /* border: 1px solid red; */
}

.album-share img {
  height: 35px;
  margin: 0 1.5rem 0 1.5rem;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.album-share img:focus {
  outline: none;
}

/* header */

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

#header {
  height: 100vh;
  width: 50%;
  /* border: 1px solid blue; */
}

.bg-image {
  background: black url("img/albums/havenArtwork.png") no-repeat bottom right;
  background-size: contain;
  position: absolute;
  width: 100%;
  height: 100%;
  float: right;
  z-index: -3;

  /* border: 1px solid yellow; */
}

.header-container {
  /* border: 1px solid orange; */
  margin-left: 100px;
  margin-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  mix-blend-mode: difference;
}

.title-container {
  overflow: hidden;

  /* border: 1px solid brown; */
}

h1 {
  font-family: "Lato";
  font-size: 5rem;
  font-weight: 300;
  /* border: 1px solid green; */
  vertical-align: middle;
  text-align: left;
  position: relative;
  color: white;

  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* nav */

/* nav {
  border: 1px solid red;
} */

nav ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: 80px;
  overflow: hidden;
}

nav ul li a {
  font-family: "Work Sans";
  font-size: 3rem;
  font-weight: 200;
  list-style: none;
  color: rgb(255, 255, 255);
  mix-blend-mode: difference;
  text-decoration: none;
}

nav ul li a:hover {
  cursor: pointer;
}

nav ul li {
  /* border: 1px solid red; */
  margin: 0 0 50px 0;
  display: inline-block;
  position: relative;
}

nav ul li:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  mix-blend-mode: difference;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

nav ul li:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* listen */

.listen {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(0, 0, 0);
  height: 100vh;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  /* border: 1px solid blue; */
}

.reel {
  width: 75%;
  height: 600px;
}

/* bio */

.bio {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  height: 100vh;
  width: 70%;
  float: right;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* border: 1px solid blue; */
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 2%;
  left: 2%;
  /* border: 1px solid red; */
  height: 30px;
}

.close-line {
  margin: 4px 4px;
  width: 30px;
  height: 2px;
  border-radius: 30px;
  background-color: white;
}

.tunnel-img {
  width: 50%;
  margin-left: 60px;
  /* border: 1px solid red; */
}

/* .studio-img {
  height: 550px;
  margin-left: 60px;
} */

.studio-2-img {
  height: 550px;
  margin-left: 60px;
}

.text-wrapper {
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
  align-items: center;
  /* width: auto; */
  height: auto;
}

h2 {
  font-family: "Lato";
  font-size: 1.8rem;
  font-weight: 300;
  /* border: 1px solid green; */
  vertical-align: middle;
  text-align: justify;
  color: white;

  margin: 20px 75px 20px 50px;
  line-height: 175%;

  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* contact */

.contact-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  mix-blend-mode: color;
}

.contact img {
  height: 35px;
  margin: 0 1.5rem 0 0;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contact img:focus {
  outline: none;
}

/* copyright */

.copyright {
  /* background: white; */
  color: white;
  font-size: 1.5rem;
  font-family: "Lato";
  font-weight: 300;
  text-align: left;
  mix-blend-mode: difference;
  /* border: 1px solid blue; */
  width: 100%;

  position: absolute;
  bottom: 0;
  margin: 50px 100px;

  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* MEDIA QUERIES */

@media screen and (max-width: 2000px) {
  .bio {
    justify-content: flex-start;
  }

  .text-wrapper {
    width: 50%;
  }

  h2 {
    font-size: 18px;
  }

  .studio-2-img {
    height: auto;
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .bio {
    justify-content: flex-end;
  }

  #header {
    height: 100%;
  }

  .bg-image {
    background: white url("img/compressed/Website\ Cover_Closed_NoBG.png")
      no-repeat bottom center;
    background-size: contain;
    position: absolute;
    bottom: 0;
  }

  .header-wrapper {
    flex-direction: column;
  }

  .header-container {
    align-items: center;
    margin: 20px 0 0 0;
  }

  nav ul {
    align-items: center;
    margin-top: 10px;
  }

  nav ul li {
    margin: 10px;
  }

  .two {
    height: 100%;
    position: relative;
    top: 0;
  }

  .album-main-wrapper {
    justify-content: flex-start;
    margin-top: 70px;
  }

  .album-main {
    height: 40%;
    width: auto;
  }

  .contact-list {
    justify-content: center;
    align-items: center;
  }

  .contact-list img {
    margin: 10px 0.75rem 0 0.75rem;
    /* margin: 10px 1.5rem 0 0; */
    height: 25px;
  }

  .copyright {
    display: none;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: black;
    mix-blend-mode: color;
    vertical-align: center;
  }

  .bio {
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .tunnel-img {
    width: 70%;
    margin: 20px 60px;
    /* border: 1px solid red; */
  }

  .studio-2-img {
    width: 50%;
    margin: 20px 60px;
    /* border: 1px solid red; */
  }

  .text-wrapper {
    width: 90%;
  }

  h2 {
    font-size: 1.45rem;
    /* border: 1px solid green; */
    vertical-align: middle;
    margin: 5px 0;
    line-height: 130%;
  }

  .listen {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* border: 1px solid blue; */
  }

  .reel {
    width: 100%;
    height: 95%;
  }
}

@media screen and (max-width: 1500px) {
  h2 {
    font-size: 1.5rem;
  }
}

@media screen and (max-height: 800px) {
  h2 {
    font-size: 1.2rem;
    /* border: 1px solid green; */
    line-height: 130%;
  }
}

/* -- MEDIA QUERIES -- */
